$white: #ffffff;
$black: #000;
$primary: #f9e4ea;
$accent: #eeb900;
$accent-break: #637aff;
$primary-font: #201F26;
$secondary: #3E3B38;
$secondary-accent: #9f9698;
$tertiary: #f7f7fb;
$grey: #F8F8F8;
$pink: #f9e4ea;
$teal: #01aaad;
$yellow: #eeb900;
$green: #4caf50;
$orange: #fbad18;
$blue: #00aeef;
$red: #FF2767;
$light-red: #ffbbb4;
$light-bg: #f1eeeb;
$amber: #ffc107;
$lime: #cddc39;
$deep-purple: #673ab7;
$cyan: #00bcd4;
$deep-pink: #880e4f;
$light-green: #8bc34a;
$light-grey: #F6F5F8;
$dark-grey: #eaeaea;
$brown: #795548;
$blue-grey: #607d8b;

$gold: #D5bf82;
$rosegold: #ddb091;
$whitegold: #e0f5fa;
$blackgold: #141414;

$classica1: #f8cece;
$classica2: #859F94;

$green-base: #a6d2c5; 
$green-gradient: linear-gradient(180deg, #a6d2c5 0%, #c9eee2 100%);
$pink-base: #f9e4ea;//f3d3d5; 
$pink-gradient: linear-gradient(180deg, #f9e4ea 0%, #feecea 100%);

$border-default: #eaeaea;
$background: #ffffff;
$background-darker: #201f26;
$border-darker: #2b2b2b;
$shadow-border: #000;
$shadow-grey: #f5f5f5;
$shadow-white-btn: #fff;