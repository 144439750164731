@import 'colors';

// body{font-family:"futura-pt",sans-serif}
// h1, h2, h3, h4, h5{margin:0; text-transform: uppercase;font-family:"termina";}
h1, h2, h3, h4, h5{margin:0; font-family:"ivymode";}

h1, h2 {
	font-weight: 300;
	font-size: 1.4rem;
}
@supports (font-size: clamp(1.4rem, 1.8vw, 1.8rem)) {
	h1, h2 {
		font-size: clamp(1.4rem, 1.8vw, 1.8rem);
	}
}
h3 {
	font-weight: 300;
	font-size: 1rem;
}
@supports (font-size: clamp(1.1rem, 3.5vw, 1.1rem)) {
	h3 {
		font-size: clamp(1rem, 3.5vw, 1.1rem);
	}
}
h4 {
	font-weight: 300;
	font-size: 1rem;
}
@supports (font-size: clamp(1rem, 2.5vw, 1.1rem)) {
	h4 {
		font-size: clamp(1rem, 2.5vw, 1.1rem);
	}
}

h5 {
	font-weight: 300;
	font-size: 14px;
}