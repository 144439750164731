@use "sass:math";

@mixin box {
  border: 1px solid rgba(0,0,0,0.1);
  -webkit-border-radius: 4px;
     -moz-border-radius: 4px;
      -ms-border-radius: 4px;
          border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 1px 2px rgba(0,0,0,0.1);
}
/*
.box { @include box; }
*/

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
      -ms-border-radius: $radius;
          border-radius: $radius;
}
/*
border radius z vendorami
.box { @include border-radius(10px); }
*/

/*transition*/
@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}
/*
a {
  color: gray;
  @include transition(color .3s ease);
  &:hover {
    color: black;
  }
}*/

/*absolute center*/
@mixin center($position) {
  position: absolute;
 
  @if $position == 'vertical' {
    top: 50%;
    -webkit-transform: translate3d(0, -50%, 0);
    -ms-transform: translate3d(0, -50%, 0);
    transform: translate3d(0, -50%, 0);
  }
  @else if $position == 'horizontal' {
    left: 50%;
    -webkit-transform: translate3d(-50%, 0, 0);
    -ms-transform: translate3d(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0);
  }
  @else if $position == 'both' {
    top: 50%;
    left: 50%;
    -webkit-transform: translate3d(-50%, -50%, 0);
    -ms-transform: translate3d(-50%, -50%, 0);
    transform: translate3d(-50%, -50%, 0);
  }
}
/*
.parent {
  position: relative;
  .child {
    @include center(both);
  }
}
*/

/*triangle*/
@mixin css-triangle($color, $direction, $size: 6px, $position: absolute, $round: false) {
    width: 0;
    height: 0;
    @if $round {
        border-radius: 3px;
    }
    @if $direction == down {
        border-left: $size solid transparent;
        border-right: $size solid transparent;
        border-top: $size solid $color;
        margin-top: 0 - round( math.div($size,2.5) );
    } @else if $direction == up {
        border-left: $size solid transparent;
        border-right: $size solid transparent;
        border-bottom: $size solid $color;
        margin-bottom: 0 - round( math.div($size,2.5) );
    } @else if $direction == right {
        border-top: $size solid transparent;
        border-bottom: $size solid transparent;
        border-left: $size solid $color;
        margin-right: -$size;
    } @else if  $direction == left {
        border-top: $size solid transparent;
        border-bottom: $size solid transparent;
        border-right: $size solid $color;
        margin-left: -$size;
    }
}
/*
.triangle {
  @include css-triangle(red, up, 100px);
}*/
